import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import HeaderColor from "../../components/HeaderColor"
import HeaderCover from "../../components/HeaderCoverNoneOverlay"
import OrganizationLink from "../../components/OrganizationLink"
import Footer from "../../components/Footer"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const IntroContainer = styled.div`
  margin: 60px 0 10px 0;
  .introWrapper {
    font-family: "Kanit", sans-serif;
    margin: 0 auto;
    text-align: center;
    span {
      font-weight: 300;
      font-size: 14px;
      color: #7497b2;
    }
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 80px 0 20px 0;
  }
`
const ExecutiveContainer = styled.div`
  margin-bottom: 100px;
  .cardWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-item: center;
    justify-content: center;
    .item {
      width: 100%;
      padding: 0 15px;
      margin: 40px auto;
      text-align: center;
      font-family: "Kanit", sans-serif;
      .imageWrapper {
        margin: 0 auto 20px auto;
        text-align: center;
        .gatsby-image-wrapper {
          max-width: 290px;
          border-radius: 100%;
          img {
            border-radius: 100%;
          }
        }
      }
      .name {
        font-weight: 700;
        font-size: 20px;
        color: #181818;
        margin-bottom: 5px;
      }
      .position {
        font-weight: 400;
        font-size: 16px;
        color: #181818;
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (min-width: 576px) {
    margin-bottom: 120px;
    .cardWrapper {
      .item {
        width: 50%;
      }
      .item:first-of-type {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .cardWrapper {
      .item {
        width: 33.33%;
      }
    }
  }
`

const ExecutivePage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields, executivePosts } = data
  const { seoTitle, seoDescription, coverImage } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "rootMenu3Sub1" })}
        secondLink="/about-us/"
        secondText={intl.formatMessage({ id: "rootMenu3" })}
      />
      <IntroContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <div className="introWrapper">
                <span>{intl.formatMessage({ id: "rootMenu3Sub1" })}</span>
                <h2>{intl.formatMessage({ id: "subRootMenu313" })}</h2>
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <ExecutiveContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <div className="cardWrapper">
                {executivePosts &&
                  executivePosts.edges.map((post, index) => {
                    const name = post.node.name
                    const position = post.node.position
                    const image = getImage(post.node.image)
                    return (
                      <div key={index} className="item">
                        <div className="imageWrapper">
                          <GatsbyImage image={image} alt="" />
                        </div>
                        <h3
                          className="name"
                          dangerouslySetInnerHTML={{
                            __html: name,
                          }}
                        />
                        <p
                          className="position"
                          dangerouslySetInnerHTML={{
                            __html: position,
                          }}
                        />
                      </div>
                    )
                  })}
              </div>
            </Col>
          </Row>
        </div>
      </ExecutiveContainer>
      <OrganizationLink />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ExecutivePageQuery($locale: String) {
    pageFields: allContentfulExecutivePage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    executivePosts: allContentfulExecutivePosts(
      sort: { fields: createdAt, order: ASC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          name
          position
          image {
            gatsbyImageData(width: 290, placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
  }
`

export default injectIntl(ExecutivePage)
